ul,li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
  display: inline-block;
}

@font-face {
  font-family: neo-latina;
  src: url(./fonts/neo_latina/neo-latina.ttf);
}

:root {
  --fs-base: 14px;
  --fs-title: 90px;
  --fs-section-title: 40px;
  --fs-button: 20px;
  --fs-block-title: 26px;

  --line-height: 10rem;

  --color-primary: #079FCC;
  --color-secondary: #89E4FF;
  --color-white: #ffffff;

  --font-neo-latina: neo-latina;
  --font-default: 'Roboto Mono', monospace;

}

@media (min-width: 768px) {
  :root {
    --fs-base: 16px;
  --fs-title: 150px;
  --fs-section-title: 45px;
  --fs-button: 26px;
  --fs-block-title: 32px;
  
    --line-height: 10px;
  
    --color-primary: #079FCC;
    --color-secondary: #89E4FF;
    --color-white: #ffffff;
  
    --font-neo-latina: neo-latina;
    --font-default: 'Roboto Mono', monospace;
  
  }
}

@media (min-width: 1600px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1405px !important;
  }
}

body {
  font-size: var(--fs-base);
  font-family: var(--font-default) !important;
  color: #ffffff;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}

section {
  padding: 40px 0;
}

@media (min-width: 992px) {
  section {
    padding: 70px 0;
  } 
}

.y0-mint__row {
  margin-bottom: 70px;
}

@media (min-width: 992px) {
  .y0-mint__row {
    margin-bottom: 140px;
  }
}

.y0-hexagon__background {
  top: 0px;
}

/* @media(min-width: px) { */
  .y0-hexagon__background {
    top: -250px;
  }
  .y0-hexagon__B {
    transform: translatex(50%);
  }
/* } */

.y0-title__section {
  font-size: var(--fs-section-title);
  font-weight: bold;
  font-style: italic;
  color: var(--color-primary);
  text-transform: uppercase;
}
.y0-title__section--green {
  color: #07CC5A;
}
.y0-title__section--orange {
  color: #B76513;
}
.y0-section__subtitle {
  font-size: var(--fs-block-title);
  font-weight: lighter;
  color: var(--color-primary);
}
.y0-para {
  color: #ffffff;
}
.y0-input {
  padding: 15px 50px;
  border: 0;
  background: #BFE2ED;
}
.y0-input__error {
  color: #ff2e2e;
}
.y0-input::placeholder {
  color: #143359;
}
.y0-input:focus {
  outline: none;
}
.y0-button {
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.2s ease;
}
.y0-button__inwrap img {
  transition: all 0.2s ease;
}
.y0-button:hover .y0-button__inwrap img {
  transform: translateX(10px);
}
.y0-button:hover {
  transform: translateX(15px);
}
.y0-btn__quantity {
  padding: 14px 12px;
  border: 0;
  background-color: rgba(256, 256, 256, 0.16);
  color: #ffffff;
  font-size: 24px;
  transition: all 0.2s ease;
  cursor: pointer; 
}
.y0-btn__quantity:hover {
  background-color: rgba(256, 256, 256, 0.26);
}
.y0-input__quantity {
  width: 55px;
  font-size: 40px;
  color: #BFE2ED;
  background: none;
  border: 0;
  text-align: center;
}
.y0-input__quantity:focus {
  outline: none;
}

.y0-button__inwrap {
  padding: 35px 18px 3px 14px;
}

.y0-button__inwrap {
  background-image: url("./assets/button-background.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  padding: 35px 16px 3px 14px;
}

@media (min-width: 768px) {
  .y0-button__inwrap {
    padding: 35px 38px 3px 14px;
  }
}

@media (min-width: 1200px) {
  .y0-mint__right-content {
    margin-bottom: -200px;
  }
}

@media (min-width: 1400px) {
  .y0-mint__right-content {
    margin-bottom: -90px;
  }
}

/* @media (min-width: 768px) {
  .y0-button__inwrap {
    padding: 40px 50px 5px 20px;
  }
} */

.y0-main {
  padding-top: 120px;
  /* background: #0B0F17; */
}
/* .y0-title__page--sticky {
  padding-top: 0 !important;
  position: relative !important;
} */

@media (min-width: 768px) {
  .y0-main {
    padding-top: 220px;
    /* background: #0B0F17; */
  }
  .y0-section .container {
    padding-left: 110px;
  }
  .y0-title__page-vertical {
    position: absolute;
    transform: rotate(90deg);
  }
  .y0-title__page-vertical span {
    transform: rotate(180deg);
    padding-right: 70px;
  }
  .y0-title__page--sticky {
    transform: translateX(-110px) !important;
  }
}

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
  }
}

.y0-title__page-vertical {
  font-size: var(--fs-section-title);
  line-height: 40px;
  font-family: var(--font-neo-latina);
  left: 10px;
  transform-origin: 10% 40%;
  color: #708BA7;
}

.y0-header {
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease;
}
@media (max-width: 768px) {
  .y0-header {
    padding: 10px;
  }
}
.y0-header.hidden {
  transform: translateY(-80px);
  transition: all 0.3s ease;
}
.y0-header.y0-header__scrolledDown {
  background-color: rgba(255,255,255,0.75)!important;
  backdrop-filter: blur(10px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
}

.y0-header__logo {
  height: 110px;
}

.y0-header__btn-toggle {
  padding-top: 10px !important;
}

@media (min-width: 768px) {
  .y0-header__logo {
    height: 204px;
  }
}

.y0-header__menu-items {
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.3s ease;
}
@media(min-width: 992px) {
  .y0-header__menu-items {
    padding-top: 33px;
    margin-right: -12px;
  }
  .y0-header__scrolledDown .y0-header__menu-items {
    padding-top: 20px;
  }
}

.y0-header__menu-item {
  font-size: var(--fs-base);
  /* margin-right: 40px; */
}
.y0-header__buynow {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  padding: 30px 60px!important;
  background: #079fcc;
  color: #ffffff!important;
  transition: all .4s ease-in-out !important;
 }
.y0-header__buynow:hover {
  opacity: .7;
}

.y0-header__connect {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  margin-left: 20px;
  padding: 10px 20px!important;
  background: #079fcc;
  color: #ffffff!important;
  transition: all .4s ease-in-out !important;
 }
.y0-header__connect:hover {
  opacity: .7;
}
.y0-header__menu-item a {
  font-size: 14px;
  color: #0B0F17;
  font-weight: 600;
  padding-left: 15px !important;
 }
 .y0-header__menu-item a:hover {
  color: rgba(0,0,0,.6);
 }
@media (min-width: 992px) {
  .y0-header__menu-item a {
    padding: 0 22px 0 0 !important;
  }
}

@media (max-width: 992px) {
  .y0-header__connect {
    margin-left: 0px;
    margin-top: 10px;
  }
  .y0-header__navbar-collapse {
    width: 100%;
    position: absolute;
    background-color: #fbfbfb;
    top: 80px;
    z-index: 11;
    color: #079fcc;
  }
  .y0-header__menu-item a {
    color: #079fcc;
  }
}
.navbar-toggler:focus {
  box-shadow: none !important;
  outline: 0 !important;
}
.y0-header__menu-line {
  width: 30px;
  height: 2px;
  background: #0b0f17;
  margin-bottom: 5px;
}
.y0-header__menu-line:last-child {
  margin-bottom: 0;
}
 
.y0-hero {
  z-index: 1;
  /* background: #ffffff; */
}
 .y0-hero__title {
  font-size: var(--fs-title);
  line-height: calc(var(--fs-title) - 6px);
  font-family: var(--font-neo-latina);
  color: var(--color-primary);
}

@media (min-width: 768px) {
  .y0-hero__title {
    line-height: calc(var(--fs-title) - 60px);
  }
  .y0-hero__content {
    padding-left: 100px;
  }
}

.y0-hero__para {
  font-size: calc(var(--fs-base) + 2px);
  line-height: calc(var(--fs-base) + 8px);
  font-weight: bold;
  font-style: italic;
  color: var(--color-primary);
}


.y0-section {
  background: #0B0F17;
}
.y0-section__sec {
  margin-top: -125px;
  padding-top: 180px;
  background: #0B0F17;
  background-repeat: no-repeat;
  background-position: bottom;
}
.y0-mint__list-wrap {
  color: #ffffff;
}
.y0-mint__count-wrap {
  color: var(--color-secondary);
}
.y0-mint__count, .y0-mint__cost-eth {
  font-size: 20px;
}
@media (min-width: 768px) {
  .y0-section__sec {
    margin-top: -155px;
    padding-top: 205px;
  }
  .y0-mint__count, .y0-mint__cost-eth {
    font-size: 30px;
  }
}

.y0-mint__cost-eth {
  color: #BFE2ED;
}
.y0-mint__cost-dollar {
  color: #BFE2ED;
}

.y0-footer__content {
  color: #BFE2ED;
}

.y0-section__whitelist {
  padding-bottom: 100px;
}

@media (min-width: 768px) {
  .y0-section__whitelist {
    padding-bottom: 250px;
  }
}

.y0-modal {
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.03);
}
.y0-modal__content {
  background: #0B0F17 !important;
  text-align: center;
  padding: 50px 0;
}
@media (min-width: 768px) {
  .y0-modal__content {
      padding: 50px 60px;
  }
}
.y0-modal__close {
  background: none;
  border: none;
  top: 10px;
  right: 10px;
}
.y0-modal__title {
  font-size: calc(var(--fs-block-title) - 10px);
  color: var(--color-primary);
  font-weight: bold;
  font-style: italic;
  text-align: left;
}
.y0-btn__input {
  width: 100%;
  background: #FFFFFF;
  border: none;
  padding: 15px 50px;
}
.y0-btn__modal {
  width: 100%;
  border: none;
  font-size: 22px;
  position: relative;
  padding: 0;
  cursor: pointer;
}
.y0-btn__modal::before {
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #ffffff;
  position: absolute;
  left: -7px;
  top: -7px;
  z-index: 0;
  transition: all 0.1s ease;
}
.y0-btn__modal:hover::before  {
  left: 0px;
  top: 0px;
}
.y0-btn__modal-inwrap {
  padding: 15px 50px;
  background: var(--color-secondary);
  position: relative;
  transition: all 0.3s ease;
  color: black !important;
}
.y0-btn__modal-inwrap:hover {
  background: #65d4f5;
}

.y0-footer__copyrights-text {
  font-size: 12px;
  color: #76A4B2;
}

.y0-footer__social-link {
  transition: all 0.2s ease;  
}

.y0-footer__social-link:hover {
  transform: translateY(-3px);
}

.y0-footer__copyrights-text a {
  color: #76A4B2;
}

.y0-footer__copyrights-text a:hover {
  color: #079fcc;
}

#test {
  background-color: #186418;
  opacity: 0.7;
  width: 100%;
}

.y0-load_icon {
  position: absolute;
  top: calc(50% - 100px) !important;
  left: calc(50% - 100px) !important;
  z-index: 10;
}
.y0-auth_message {
  color: red;
  height: 40px;
}
.y0-auth_blank {
  height: 40px;

}
.y0-transaction_hash {
  color: white;
}


.y0_paper_email {
  display: flex;
  flex-direction: column;
}
.y0_paper_email_input {
  width: 100%;
  background-color: rgba(28,31,38, 0.5);
  border-radius: 7px;
  padding: 7px 10px;
  color: white;
  border: 1px solid #2B2E35;
  outline: 2px solid transparent;
  margin: 3px 0;
}
.y0_paper_email_btn {
  width: 100%;
  background-color: #00a80c;
  opacity: 0.4;
  border-radius: 7px;
  padding: 7px 0;
  color: white;
  margin: 3px 0;
}
.y0_paper_email_btn:hover {
  color: white;
  opacity: 1;
}
.y0_paper_email_login {
  color: white;
}
